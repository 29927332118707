<template>
    <div class="Profile-detailed-lesson">
        <ul class="detailed-lesson-tabs">
            <li class="detailed-lesson-tabs__item">
                <a class="detailed-lesson-tabs__title"
                   @click.prevent="setActive('students')"
                   :class="{ active: isActive('students') }" href="#students">
                    {{$t("profile.detailed-lesson.students")}}
                </a>
            </li>
            <li class="detailed-lesson-tabs__item">
                <a class="detailed-lesson-tabs__title"
                   @click.prevent="setActive('home-work')"
                   :class="{ active: isActive('home-work') }" href="#home-work">
                    {{$t("profile.detailed-lesson.home-work")}}
                </a>
            </li>
            <li class="detailed-lesson-tabs__item" v-if="classInfo.test_available">
                <a class="detailed-lesson-tabs__title"
                   @click.prevent="setActive('test')"
                   :class="{ active: isActive('test') }" href="#home-work">
                    {{$t("profile.detailed-lesson.test")}}
                </a>
            </li>
        </ul>

        <div class="tab-content">
            <div class="tab-pane fade" :class="{ 'active show': isActive('students') }" id="students">
                <div class="students-tab" :class="{loading: studentsLoading}">
                    <div class="students-tab__header">
                        <router-link tag="button" to="/teacher-schedule" class="students-tab__back-btn">
                            <img src="/images/profile/arrow-prev.svg" width="9" height="15"  class="mr-3" alt="">
                            <div>
                                {{$t("profile.detailed-lesson.back-btn")}}
                            </div>
                        </router-link>
                        <div class="text-center" v-if="classInfo.name">
                            <div class="students-tab__class">
                                {{ classInfo.name }} {{$t("profile.detailed-lesson.class")}}
                            </div>
                            <div class="students-tab__date">
                                {{ classInfo.date }}
                            </div>
                        </div>
                        <el-button :loading="scheduleSaveLoading" class="students-tab__save-btn" @click="saveSchedule">
                            {{$t("profile.detailed-lesson.save-btn")}}
                        </el-button>
                    </div>
                    <div class="students-tab__table">
                        <table class="students-table">
                            <tr>
                                <th class="students-table__header">{{$t("profile.detailed-lesson.name")}}</th>
                                <th class="students-table__header">{{$t("profile.detailed-lesson.grade")}}</th>
                                <th class="students-table__header">{{$t("profile.detailed-lesson.control-score")}}</th>
                                <th class="students-table__header">{{$t("profile.detailed-lesson.status")}}</th>
                                <th class="students-table__header">{{$t("profile.detailed-lesson.comment")}}</th>
                            </tr>
                            <tr v-for="student in students">
                                <td class="students-table__name">{{ student.student.first_name }} {{ student.student.last_name }} {{ student.student.patronymic }}</td>
                                <td class="students-table__grade">
                                    <el-select
                                        :placeholder="$t('profile.detailed-lesson.grade')"
                                        v-model="student.schedule.grade">
                                        <el-option
                                            v-for="item in 100"
                                            :key="+item"
                                            :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </td>
                                <td class="students-table__grade">
                                    <el-select
                                        :placeholder="$t('profile.detailed-lesson.control-score')"
                                        v-model="student.schedule.control_score">
                                        <el-option
                                            v-for="item in 100"
                                            :key="+item"
                                            :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </td>
                                <td class="students-table__status">
                                    <el-select
                                        :placeholder="$t('profile.detailed-lesson.status')"
                                        v-model="student.schedule.status">
                                        <el-option
                                            v-for="item in [
                                                {
                                                    id: 1,
                                                    name: $t('profile.detailed-lesson.was-absent')
                                                },
                                                {
                                                    id: 2,
                                                    name: $t('profile.detailed-lesson.attended')
                                                },
                                                {
                                                    id: 3,
                                                    name: $t('profile.detailed-lesson.was-late')
                                                },
                                            ]"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </td>
                                <td class="students-table__comment">
                                    <b-form-textarea
                                        :placeholder="$t('profile.detailed-lesson.comment')"
                                        rows="1"
                                        v-model="student.schedule.comment"
                                        max-rows="4"
                                        class="students-table__textarea"
                                    ></b-form-textarea>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>


                <div class="students-tab-mob" :class="{loading: studentsLoading}">
                    <router-link tag="button" to="/teacher-schedule" class="students-tab__back-btn">
                        <img src="/images/profile/arrow-prev.svg" width="9" height="15"  class="mr-3" alt="">
                    </router-link>
                    <div class="text-center" v-if="classInfo.name">
                        <div class="students-tab__class">
                            {{ classInfo.name }} {{$t("profile.detailed-lesson.class")}}
                        </div>
                        <div class="students-tab__date">
                            {{ classInfo.date }}
                        </div>
                    </div>
                    <div class="students-tab-mob__item" v-for="student in students" :key="student.id">
                        <button class="students-tab-mob__name-btn">
                            <div>{{ $t('profile.detailed-lesson.name') }}</div>
                            <div>&#9662;</div>
                        </button>
                        <div class="students-tab-mob__fio">
                            {{ student.student.first_name }} {{ student.student.last_name }} {{ student.student.patronymic }}
                        </div>
                        <div>
                            <div class="students-tab-mob__row">
                                <form-group :label="$t('profile.detailed-lesson.grade')" name="grade">
                                    <el-select
                                        :placeholder="$t('profile.detailed-lesson.grade')"
                                        v-model="student.schedule.grade">
                                        <el-option
                                            v-for="item in 100"
                                            :key="+item"
                                            :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </form-group>
                                <form-group :label="$t('profile.detailed-lesson.status')" name="status">
                                    <el-select
                                        :placeholder="$t('profile.detailed-lesson.status')"
                                        v-model="student.schedule.status">
                                        <el-option
                                            v-for="item in [
                                                {
                                                    id: 1,
                                                    name: $t('profile.detailed-lesson.was-absent')
                                                },
                                                {
                                                    id: 2,
                                                    name: $t('profile.detailed-lesson.attended')
                                                },
                                                {
                                                    id: 3,
                                                    name: $t('profile.detailed-lesson.was-late')
                                                },
                                            ]"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </form-group>
                            </div>
                            <form-group :label="$t('profile.detailed-lesson.control-score')" name="grade">
                                <el-select
                                    :placeholder="$t('profile.detailed-lesson.control-score')"
                                    v-model="student.schedule.control_score">
                                    <el-option
                                        v-for="item in 100"
                                        :key="+item"
                                        :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </form-group>
                            <form-group :label="$t('profile.detailed-lesson.comment')" name="comment">
                                <b-form-textarea
                                    :placeholder="$t('profile.detailed-lesson.comment')"
                                    rows="1"
                                    v-model="student.schedule.comment"
                                    max-rows="4"
                                    class="students-table__textarea"
                                ></b-form-textarea>
                            </form-group>
                        </div>
                    </div>
                    <b-pagination
                        v-if="total > perPage"
                        v-model="currentPage"
                        :total-rows="total"
                        :per-page="perPage"
                        last-number
                        first-number
                        hide-goto-end-buttons
                        align="center"
                        next-text=">"
                        prev-text="<"
                    ></b-pagination>
                    <el-button :loading="scheduleSaveLoading" class="students-tab__save-btn" @click="saveSchedule">
                        {{$t("profile.detailed-lesson.save-btn")}}
                    </el-button>
                </div>
            </div>
            <div class="tab-pane fade" :class="{ 'active show': isActive('home-work') }" id="home-work">
                <div class="home-work-tab">
                    <div class="home-work-tab__class">
                        {{ classInfo.name }} {{$t("profile.detailed-lesson.class")}}
                    </div>
                    <div class="home-work-tab__date">
                        <img src="/images/profile/calendar.svg" class="mr-3" width="20" height="23" alt="">
                        <div>
                            {{ classInfo.date }}
                        </div>
                    </div>
                    <div class="home-work-tab__header">
                        <div class="home-work-tab__title">
                            {{$t("profile.detailed-lesson.lessons-materials")}}
                        </div>
                        <button class="home-work-tab__add-btn" @click.prevent="showModalHW">
                            <img src="/images/profile/plus-icon.svg" width="13" height="13"  class="mr-2" alt="">
                            <div>
                                {{$t("profile.detailed-lesson.add-btn")}}
                            </div>
                        </button>
                    </div>
                    <table class="home-work-table" v-if="materials.length">
                        <tr class="home-work-table__header-row">
                            <th class="home-work-table__header">{{$t("profile.detailed-lesson.task-name-title")}}</th>
                            <th class="home-work-table__header">{{$t("profile.detailed-lesson.task-type-title")}}</th>
                            <th class="home-work-table__header">{{$t("profile.detailed-lesson.subject-title")}}</th>
                            <th class="home-work-table__header">{{$t("profile.detailed-lesson.theme-title")}}</th>
                            <th class="home-work-table__header"></th>
                        </tr>
                        <tr v-for="(material, index) in materials" class="home-work-table__body-row">
                            <td class="home-work-table__task-name">
                                <div>
                                    <span class="home-work-table__title">{{$t("profile.detailed-lesson.task-name-title")}}</span>
                                    {{ material.name }}
                                </div>
                            </td>
                            <td class="home-work-table__task-type">
                                <div>
                                    <span class="home-work-table__title">{{$t("profile.detailed-lesson.task-type-title")}}</span>
                                    {{ taskTypes.find(item => item.id === material.type).name }}
                                </div>
                            </td>
                            <td class="home-work-table__subject">
                                <div>
                                    <span class="home-work-table__title">{{$t("profile.detailed-lesson.subject-title")}}</span>
                                    {{ material.subject_name }}
                                </div>
                            </td>
                            <td class="home-work-table__theme">
                                <div>
                                    <span class="home-work-table__title">{{$t("profile.detailed-lesson.theme-title")}}</span>
                                    {{ material.topic_name }}
                                </div>
                            </td>
                            <td class="home-work-table__delete">
                                <button @click="removeMaterial(index)" class="home-work-table__delete-btn float-right">
                                    <img src="/images/profile/delete-icon.svg" width="10" height="10" alt="">
                                </button>
                            </td>
                        </tr>
                    </table>
                    <div class="mt-2 mb-5 text-center text-pink" v-else>
                        {{ $t("profile.empty")}}
                    </div>
                    <div class="home-work-tab__title">
                        {{$t("profile.detailed-lesson.home-work-title")}}
                    </div>
                    <div class="add-home-work">
                        <div class="add-home-work__col">
                            <div class="form-group">
                                <label class="form-label">{{ $t('profile.detailed-lesson.name-of-home-work') }}</label>
                                <el-input :placeholder="$t('profile.detailed-lesson.name-of-home-work-placeholder')"
                                          v-model="homework.title"></el-input>
                            </div>
                            <div class="add-home-work__title pt-3">
                                {{ $t('profile.detailed-lesson.comment-2') }}
                            </div>
                            <b-form-textarea
                                :placeholder="$t('profile.detailed-lesson.comment')"
                                rows="13"
                                v-model="homework.comment"
                                class="add-home-work__textarea"
                                no-resize
                            ></b-form-textarea>
                        </div>
                        <div class="add-home-work__col">
                            <div class="form-group">
                                <label class="form-label">{{ $t('profile.detailed-lesson.theme') }}</label>
                                <el-select
                                    required
                                    :placeholder="$t('profile.detailed-lesson.theme-placeholder')"
                                    v-model="homework.topic_id">
                                    <el-option
                                        v-for="item in themes"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="form-group">
                                <label class="form-label">{{ $t('profile.detailed-lesson.home-work-date') }}</label>
                                <el-date-picker
                                    :picker-options="pickerOptions"
                                    value-format="yyyy-MM-dd"
                                    v-model="homework.deadline"
                                    type="date"
                                    placeholder="____-__-__">
                                </el-date-picker>
                            </div>
                            <div class="add-home-work__title pt-2 pb-3">
                                {{ $t('profile.detailed-lesson.download-files') }}
                            </div>
                            <el-upload
                                class="add-home-work__upload"
                                drag
                                :headers="{Authorization: 'Bearer ' + this.$store.state.auth.token}"
                                :action="uploadUrl"
                                v-model="homework.files"
                                :file-list="fileList"
                                multiple
                                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,audio/mpeg,video/mp4,text/plain"
                                :on-progress="onProgress"
                                :on-remove="onRemove">
                                <img src="/images/profile/cloud-icon-lila.svg" width="100" height="100" class="add-home-work__icon-pink" alt="">
                                <div class="add-home-work__tip">
                                    {{ $t('profile.detailed-lesson.files-size') }}
                                </div>
                                <div class="add-home-work__drag-and-drop">
                                    {{ $t('profile.detailed-lesson.drag-and-drop') }}
                                </div>
                                <div class="add-home-work__select">
                                    {{ $t('profile.detailed-lesson.select') }}
                                </div>
                            </el-upload>
                        </div>
                    </div>
                    <div class="add-home-work__btns">
                        <button class="add-home-work__cancel" @click.prevent="setActive('students')">
                            {{ $t('profile.detailed-lesson.cancel-btn') }}
                        </button>
                        <button class="add-home-work__save" @click="$bvModal.show('modal-send-hw')">
                            {{ $t('profile.detailed-lesson.send-btn') }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" :class="{ 'active show': isActive('test') }" id="test">
                <div class="home-work-tab">
                    <div class="home-work-tab__class">
                        {{ classInfo.name }} {{$t("profile.detailed-lesson.class")}}
                    </div>
                    <div class="home-work-tab__date">
                        <img src="/images/profile/calendar.svg" class="mr-3" width="20" height="23" alt="">
                        <div>
                            {{ classInfo.date }}
                        </div>
                    </div>
                    <div class="add-home-work">
                        <div class="add-home-work__col w-100">
                            <div class="form-group">
                                <label class="form-label">{{ $t('profile.detailed-lesson.test-label') }}</label>
                                <el-select
                                    :loading="loading"
                                    :placeholder="$t('profile.detailed-lesson.test-placeholder')"
                                    v-model="test.id">
                                    <el-option
                                        v-for="item in tests"
                                        :key="item.id"
                                        :label="item['title_' + $i18n.locale]"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="form-group">
                                <div class="add-home-work__title pt-2 pb-3">
                                    <label>{{ $t('profile.detailed-lesson.time-limit') }}</label>
                                    <el-radio-group v-model="test.time_limit" class="d-block">
                                        <el-radio-button :label="0">{{ $t('profile.detailed-lesson.no') }}</el-radio-button>
                                        <el-radio-button :label="1">{{ $t('profile.detailed-lesson.yes') }}</el-radio-button>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div class="form-group" v-if="test.time_limit">
                                <label class="form-label">{{ $t('profile.detailed-lesson.home-work-date') }}</label>
                                <el-date-picker
                                    v-model="test.time"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="-"
                                    :start-placeholder="$t('profile.detailed-lesson.quiz-start')"
                                    :end-placeholder="$t('profile.detailed-lesson.quiz-end')">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="add-home-work__btns">
                        <button class="add-home-work__cancel" @click.prevent="setActive('students')">
                            {{ $t('profile.detailed-lesson.cancel-btn') }}
                        </button>
                        <button class="add-home-work__save" @click="saveTest">
                            {{ $t('profile.detailed-lesson.send-btn') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-send-hw" hide-footer hide-header centered class="modal-send-hw">
            <b-button class="modal-send-hw__close" block @click="$bvModal.hide('modal-send-hw')">
                <img src="/images/profile/close-icon.svg" alt="">
            </b-button>
            <div class="modal-send-hw__content">
                <img src="/images/profile/pink-tick.svg" width="96" height="96" alt="">
                <div class="modal-send-hw__confirm">
                    {{ $t('profile.detailed-lesson.confirm-hw') }}
                </div>
                <div class="modal-send-hw__confirm-desc">
                    {{ $t('profile.detailed-lesson.confirm-hw-desc') }}
                </div>
                <div class="modal-send-hw__btns">
                    <button class="modal-send-hw__save" @click="saveTopicContents">
                        {{ $t('profile.detailed-lesson.send-btn') }}
                    </button>
                    <button class="modal-send-hw__cancel">
                        {{ $t('profile.detailed-lesson.cancel-btn') }}
                    </button>
                </div>
            </div>
        </b-modal>

        <b-modal id="modal-add-hw" hide-footer hide-header centered class="modal-add-hw" size="lg">
            <b-button class="modal-add-hw__close" block @click="$bvModal.hide('modal-add-hw')">
                <img src="/images/profile/close-lila.svg" alt="">
            </b-button>
            <div class="modal-add-hw__content">
                <div class="home-work-tab__class">
                    {{ classInfo.name }} {{$t("profile.detailed-lesson.class")}}
                </div>
                <div class="home-work-tab__date">
                    <img src="/images/profile/calendar.svg" class="mr-3" width="20" height="23" alt="">
                    <div>
                        {{ classInfo.date }}
                    </div>
                </div>
                <div class="modal-add-hw__title">
                    {{ $t('profile.detailed-lesson.lessons-materials') }}
                </div>
                <div class="modal-add-hw__select">
                    <div class="form-group">
                        <label class="form-label">{{ $t('profile.detailed-lesson.name-title') }}</label>
                        <el-input
                            v-model="filter.name"
                            :placeholder="$t('profile.detailed-lesson.name-placeholder')"
                        >
                        </el-input>
                    </div>
                    <div class="form-group">
                        <label class="form-label">{{ $t('profile.detailed-lesson.subject-title') }}</label>
                        <el-select
                            clearable
                            :placeholder="$t('profile.detailed-lesson.subject-placeholder')"
                            v-model="filter.subject">
                            <el-option
                                v-for="item in subjects"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="form-group">
                        <label class="form-label">{{ $t('profile.detailed-lesson.theme-title') }}</label>
                        <el-select
                            clearable
                            :placeholder="$t('profile.detailed-lesson.theme-placeholder-2')"
                            v-model="filter.topic">
                            <el-option
                                v-for="item in themes"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="form-group">
                        <label class="form-label">{{ $t('profile.detailed-lesson.task-type-title') }}</label>
                        <el-select
                            clearable
                            :placeholder="$t('profile.detailed-lesson.task-type-placeholder')"
                            v-model="filter.type">
                            <el-option
                                v-for="item in taskTypes"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="modal-add-hw__select-btns">
                    <button class="modal-add-hw__apply" @click="sendFilter">
                        {{ $t('profile.detailed-lesson.apply') }}
                    </button>
                    <button class="modal-add-hw__reset" @click="resetFilter">
                        {{ $t('profile.detailed-lesson.reset') }}
                    </button>
                </div>
                <div class="add-hw-table-wrapper" :class="{loading: loading}">
                    <table class="add-hw-table">
                        <tr>
                            <th class="add-hw-table__header"></th>
                            <th class="add-hw-table__header">{{$t("profile.detailed-lesson.name-title")}}</th>
                            <th class="add-hw-table__header">{{$t("profile.detailed-lesson.theme-title")}}</th>
                            <th class="add-hw-table__header">{{$t("profile.detailed-lesson.subject-title")}}</th>
                            <th class="add-hw-table__header">{{$t("profile.detailed-lesson.task-type-title")}}</th>
                        </tr>
                        <tr v-for="material in modalMaterials">
                            <td class="add-hw-table__check-box">
                                <el-checkbox @change="toggleMaterial(material)" v-model="material.checked"></el-checkbox>
                            </td>
                            <td class="add-hw-table__theme">
                                <div>{{ material.name }}</div>
                            </td>
                            <td class="add-hw-table__theme">
                                <div>{{ material.topic_name }}</div>
                            </td>
                            <td class="add-hw-table__subject">
                                <div>{{ material.subject_name }}</div>
                            </td>
                            <td class="add-hw-table__task-type">
                                <div>{{ taskTypes.find(item => item.id === material.type).name }}</div>
                            </td>
                        </tr>
                    </table>
                </div>
                <b-pagination
                    v-if="total > perPage"
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="perPage"
                    last-number
                    first-number
                    @change="goTo"
                    hide-goto-end-buttons
                    align="center"
                    next-text=">"
                    prev-text="<"
                ></b-pagination>
                <div class="modal-add-hw__footer-btns">
                    <button class="modal-add-hw__cancel" @click="cancelAddMaterial">
                        {{ $t('profile.detailed-lesson.cancel-btn') }}
                    </button>
                    <button class="modal-add-hw__save" @click="$bvModal.hide('modal-add-hw')">
                        {{ $t('profile.detailed-lesson.save-btn') }}
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import Vue from "vue";

export default {
    data() {
        return {
            pickerOptions: {
                firstDayOfWeek: 1
            },
            filter: {
                name: '',
                type: '',
                subject: '',
                topic: ''
            },
            scheduleSaveLoading: false,
            uploadUrl: window.API_ROOT + '/api/file/upload',
            loading: false,
            studentsLoading: true,
            currentPage: 1,
            total: 10,
            perPage: 1,
            classInfo: [],
            students: [],
            fileList: [],
            activeItem: 'students',
            homework: {
                title: '',
                comment: '',
                deadline: '',
                topic_id: '',
                schedule_id: '',
                files: []
            },
            test: {
                id: null,
                time_limit: 0,
                time: null
            },
            taskTypes: [
                {
                    id: 1,
                    name: 'Самостоятельная работа'
                },
                {
                    id: 2,
                    name: 'Контрольная работа'
                },
                {
                    id: 3,
                    name: 'Тест'
                },
            ],
            themes: [],
            tests: [],
            subjects: [],
            materials: [],
            modalMaterials: []
        }
    },
    beforeCreate() {
        let id = this.$route.params.id;
        this.studentsLoading = true;
        this.$http.get(`${window.API_ROOT}/api/schedule-student/edit/${id}`)
            .then((res) => {
                this.studentsLoading = false;
                this.students = res.body.data;
                this.classInfo = res.body.class;
            })
            .catch((e) => {
                this.studentsLoading = false;
                console.log(e);
            })
    },
    mounted() {
        if (this.$route.query.tab === 'homework') {
            this.activeItem = 'home-work'
        }
    },
    methods: {
        goTo(page) {
            this.loading = true;
            let id = this.$route.params.id;
            this.$http.post(`${window.API_ROOT}/api/teacher/topic-contents/${id}/?page=${page}`, this.filter)
                .then((res) => {
                    this.loading = false;
                    this.modalMaterials = res.body.data
                    this.total = res.body.total
                    this.perPage = res.body.perPage
                    this.modalMaterials.forEach(item => {
                        if (this.materials.find(i => i.id === item.id)) {
                            item.checked = true;
                        }
                    })
                }).catch(() => {
                this.loading = false;
            })
        },
        showModalHW() {
            this.loading = true;
            this.$bvModal.show('modal-add-hw')
            let id = this.$route.params.id;
            this.$http.post(`${window.API_ROOT}/api/teacher/topic-contents/${id}`, this.filter)
                .then((res) => {
                    this.loading = false;
                    this.modalMaterials = res.body.data
                    this.total = res.body.total
                    this.perPage = res.body.perPage
                    this.modalMaterials.forEach(item => {
                        if (this.materials.find(i => i.id === item.id)) {
                            item.checked = true;
                        }
                    })
                }).catch(() => {
                this.loading = false;
            })
        },
        getMaterials() {
            let id = this.$route.params.id;
            this.$http.get(`${window.API_ROOT}/api/schedule-student/topic-contents/${id}`)
                .then((res) => {
                    this.materials = res.body.data
                    this.subjects = res.body.subjects
                    this.themes = res.body.themes
                    this.homework = res.body.homework
                    this.homework.files.forEach(item => {
                        item.response = item.file
                        item.name = item.file.substring(6)
                        this.fileList.push(item)
                    })
                }).catch(() => {
            });
        },
        toggleMaterial(material) {
            if (material.checked) {
                this.modalMaterials.forEach(item => {
                    if (item.id !== material.id) {
                        item.checked = false
                    }
                });
                this.materials = [material];
            } else {
                this.materials.forEach((item, index) => {
                    if (item.id === material.id) {
                        this.materials.splice(index, 1)
                    }
                })
            }
        },
        getTests() {
            this.loading = true
            let id = this.$route.params.id;
            this.$http.get(`${window.API_ROOT}/api/teacher/tests/${id}`)
                .then((res) => {
                    this.loading = false
                    this.tests = res.body.data
                    if (res.body.test) {
                        this.test = res.body.test
                    }
                }).catch(() => {
                    this.loading = false
                });
        },
        removeMaterial(index) {
            this.materials.splice(index, 1);
        },
        cancelAddMaterial() {
            this.$bvModal.hide('modal-add-hw')
        },
        sendFilter() {
            this.loading = true;
            let id = this.$route.params.id;
            this.$http.post(`${window.API_ROOT}/api/teacher/topic-contents/${id}`, this.filter)
                .then((res) => {
                    this.loading = false;
                    this.modalMaterials = res.body.data
                    this.total = res.body.total
                    this.perPage = res.body.perPage
                    this.modalMaterials.forEach(item => {
                        if (this.materials.find(i => i.id === item.id)) {
                            item.checked = true;
                        }
                    })
                }).catch(() => {
                this.loading = false;
            })
        },
        resetFilter() {
            this.filter.name = '';
            this.filter.type = '';
            this.filter.subject = '';
            this.filter.topic = '';
            this.sendFilter();
        },
        onProgress(res, file, fileList) {
            this.homework.files = fileList;
        },
        onRemove(file, fileList) {
            this.homework.files = fileList;
            this.fileList = fileList;
        },
        saveSchedule() {
            this.scheduleSaveLoading = true
            let id = this.$route.params.id
            this.$http.post(`${window.API_ROOT}/api/schedule-student/update/${id}`, {
                data: this.students,
            }).then(() => {
                this.scheduleSaveLoading = false
                Vue.toastr({
                    message: 'Данные сохранены',
                    description: '',
                    type: 'success'
                })
                window.scrollTo(0,0);
            }).catch((e) => {
                this.scheduleSaveLoading = false
                console.log(e);
            })
        },
        saveTopicContents() {
            let id = this.$route.params.id;
            this.$http.post(`${window.API_ROOT}/api/schedule-student/topic-contents/update/${id}`, {
                materials: this.materials,
                homework: this.homework,
            }).then((res) => {
                if (res.body.homework_id) {
                    this.homework.id = res.body.homework_id;
                    Vue.toastr({
                        message: 'Данные сохранены',
                        description: '',
                        type: 'success'
                    })
                    this.setActive('students')
                    if (this.materials.length) {
                        this.classInfo.test_available = true
                    }
                }

            }).catch((e) => {
                console.log(e)
            })
            this.$bvModal.hide('modal-send-hw');
        },
        saveTest() {
            let id = this.$route.params.id;
            this.$http.post(`${window.API_ROOT}/api/teacher/test/${id}`, {
                test: this.test
            }).then((res) => {
                Vue.toastr({
                    message: 'Данные сохранены',
                    description: '',
                    type: 'success'
                })
                this.setActive('students')
            }).catch((e) => {
                console.log(e)
            })
        },
        isActive (menuItem) {
            return this.activeItem === menuItem
        },
        setActive (menuItem) {
            if (menuItem === 'home-work') {
                this.fileList = [];
                this.getMaterials()
            }
            if (menuItem === 'test') {
                this.getTests()
            }
            this.activeItem = menuItem
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    color: #fff;
    background-color: #473f95;
    border-color: #473f95;
    box-shadow: -1px 0 0 0 #473f95;
}
/deep/ .el-radio-button__inner:hover {
    color: #473f95;
}
.detailed-lesson-tabs {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;
}
.nav-tabs.detailed-lesson-tabs {
    border-bottom: none;
}
.detailed-lesson-tabs__title {
    width: 353px;
    text-align: center;
    border: 1px solid #473F95;
    box-sizing: border-box;
    padding: 8px 24px;
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
.detailed-lesson-tabs__title.active {
    background: #473F95;
    border: 1px solid #473F95;
    color: #FFFFFF;
}
.detailed-lesson-tabs__title:hover {
    border: 1px solid #473F95;
}
.detailed-lesson-tabs .detailed-lesson-tabs__item {
    list-style-type: none;
}
.detailed-lesson-tabs .detailed-lesson-tabs__item:first-child .detailed-lesson-tabs__title {
    border-radius: 5px 0 0 5px;
}
.detailed-lesson-tabs .detailed-lesson-tabs__item:last-child .detailed-lesson-tabs__title {
    border-radius: 0 5px 5px 0;
}
.detailed-lesson-tabs__title:hover {
    text-decoration: none;
}
.detailed-lesson-tabs__item:nth-child(2) a,
.detailed-lesson-tabs__item:nth-child(3) a{
    border-left: none;
}
.detailed-lesson-tabs__item:nth-child(2) a:hover,
.detailed-lesson-tabs__item:nth-child(3) a:hover{
    border-left: none;
}
.students-tab {
    max-width: 946px;
    margin: 0 auto;
}
.students-tab__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.students-tab__back-btn {
    color: #473F95;
    font-size: 16px;
    line-height: 59px;
    font-style: normal;
    font-weight: normal;
    background: #EEEAFB;
    border-radius: 5px 0;
    border: none;
    padding: 0 25px;
    display: flex;
    align-items: center;
}
.students-tab__class {
    color: #D23168;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 6px;
    text-align: center;
}
.students-tab__date {
    color: #473F95;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}
.students-tab__save-btn {
    background: #473F95;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    border: none;
    height: 46px;
    padding: 0 36px;
}
.students-table {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
    border-spacing: 5px;
    border-collapse: separate;
}
.students-table__header {
    background: #473F95;
    border-radius: 4px;
    color: #FFFFFF;
    padding: 8px;
}
.students-table__name {
    color: #473F95;
    padding: 12px;
    width: 284px;
    background: #E3E2F2;
    border-radius: 4px;
}
.students-table__grade {
    width: 111px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 6px 14px;
    vertical-align: top;
    color: #473F95;
}
/deep/ .students-table .el-select .el-input__inner {
    color: #473F95;
    border: none;
    height: 34px;
    padding-left: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
}
/deep/ .students-table .el-select .el-input__inner::placeholder {
    color: rgba(71, 63, 149, 0.5);
}
/deep/ .students-table .el-input__icon {
    line-height: 34px;
}
.students-table__status {
    width: 187px;
    padding: 6px 14px;
    vertical-align: top;
    background: #FFFFFF;
    border-radius: 4px;
    color: #473F95;
}
.students-table__comment {
    // padding: 12px;
    width: 354px;
    background: #FFFFFF;
    border-radius: 4px;
    color: #473F95;
}
.students-table__textarea {
    padding: 12px;
    box-shadow: none;
}
.students-table__textarea::placeholder {
    color: #b2aed6;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
}
.students-table__comment .form-control {
    color: #473F95;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
    height: 60px;
}
.students-tab.loading{
    position: relative;
}
.students-tab.loading .students-tab__header,
.students-tab.loading .students-tab__table{
    opacity: 0.5;
}
.students-tab.loading::after{
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('/images/preloader.gif');
    background-size: 25%;
    background-position: center;
    background-repeat: no-repeat;
}
.students-tab__table--before .students-table__name,
.students-tab__table--before .students-table__grade,
.students-tab__table--before .students-table__status,
.students-tab__table--before .students-table__comment {
    color: rgba(71, 63, 149, 0.5);
}
/deep/ .students-tab__table--before .el-select .el-input__inner {
    color: rgba(71, 63, 149, 0.5);
}
.students-tab__table--before .students-table__comment .form-control,
.students-tab__table--before .students-table__textarea::placeholder  {
    color: rgba(71, 63, 149, 0.5);
}
/deep/ .students-table--before .el-select .el-input__inner::placeholder {
    color: rgba(71, 63, 149, 0.5);
}

.home-work-tab {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    max-width: 1022px;
    margin: 0 auto;
    padding: 38px 24px 32px 41px;
    background: #ffffff url("/images/profile/wave-hw.svg") no-repeat bottom;
    background-size: contain;
}
.home-work-tab__class {
    color: #D23168;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 8px;
    text-align: center;
}
.home-work-tab__date {
    color: #473F95;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-work-tab__header {
    padding-top: 26px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.home-work-tab__title {
    color: #473F95;
    font-family: "Open Sans", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}
.home-work-tab__add-btn {
    background: #473F95;
    border-radius: 5px;
    padding: 14px 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    display: flex;
    align-items: center;
}
.home-work-table {
    margin-bottom: 46px;
}
.home-work-table__delete-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D23168;
    border-radius: 50%;
    border: none;
}
.home-work-table__save-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #473f95;
    border-radius: 50%;
    border: none;
}
.home-work-table__header {
    color: #D23168;
    font-family: "Open Sans", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 22px 10px 10px 16px;
}
.home-work-table__task-name {
    padding: 8px 16px;
    color: #473F95;
    font-family: 'Open Sans', 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    width: 330px;
}
.home-work-table__task-type,
.home-work-table__subject,
.home-work-table__theme {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    width: 197px;
    padding: 8px 16px 8px 16px;
}
.home-work-table__delete {
    margin-left: 8px;
}
.home-work-table__task-name > div,
.home-work-table__task-type > div,
.home-work-table__subject > div,
.home-work-table__theme > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-work-table tr:nth-child(even) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #E3E2F2;
    border-top: 1px solid #e3e2f245;
    border-bottom: 1px solid #e3e2f245;
}
.add-home-work {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-right: 106px;
}
.add-home-work__col {
    min-width: 312px;
    max-width: 360px;
}
.add-home-work__title,
.add-home-work__col .form-group .form-label {
    color: #473F95;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 9px;
}
.add-home-work__textarea {
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-shadow: none;
    color: #473F95;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    padding: 16px;
}
.add-home-work__textarea::placeholder {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}
/deep/ .add-home-work__col .el-input__inner,
/deep/ .add-home-work__col .el-input__inner::placeholder {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}
.add-home-work__col .form-group {
    display: flex;
    flex-direction: column;
}


.home-work-upload {
    margin-right: 34px;
}
.home-work-upload__title,
.your-comment__title {
    color: #473F95;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 16px;
}
.add-home-work__upload {
    color: #473F95;
    width: calc(100% + 40px);
}
/deep/ .add-home-work__upload .el-upload-dragger {
    border: 1px dashed #473F95;
}
/deep/ .add-home-work__upload .el-upload-list__item .el-icon-close-tip {
    opacity: 0;
}
/deep/ .add-home-work__upload .el-upload--picture-card:hover,
/deep/ .add-home-work__upload .el-upload:focus {
    border: none;
    color: #473F95;
}
/deep/ .add-home-work__upload .el-upload-list {
    width: 100%;
    max-width: 400px;
    margin-left: -20px;
}
/deep/ .add-home-work__upload .el-upload-list__item.is-success .el-upload-list__item-name:focus,
/deep/ .add-home-work__upload .el-upload-list__item.is-success .el-upload-list__item-name:hover {
    color: #473F95;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item{
    border: 1px dashed #473F95;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    width: 93px;
    position: relative;
    text-align: center;
    float: left;
    margin: 10px 20px;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item-name{
    margin-right: 0;
    padding-left: 0;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item-name:hover{
    text-decoration: none;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item-name .el-icon-document{
    clear: both;
    display: block;
    margin: 0 auto;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item .el-progress {
    width: 90%;
}
/deep/ .add-home-work__upload .el-upload-list .el-upload-list__item .el-icon-close{
    position: absolute;
    right: -8px;
    top: -8px;
    border: none;
    border-radius: 50%;
    background-color: #D23168;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    opacity: 1;
    color: #fff;
    padding: 4px;
    font-weight: bold;
}
.add-home-work__tip {
    font-size: 14px;
    line-height: 16px;
}
.add-home-work__drag-and-drop {
    font-size: 14px;
    line-height: 16px;
    padding: 7px 0;
}
.add-home-work__select {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
}
.home-work-upload__pass-till {
    color: #D23168;
    font-size: 18px;
    line-height: 21px;
    padding-top: 16px;
}
.add-home-work__btns,
.modal-send-hw__btns {
    text-align: center;
    margin-top: 48px;
}
.add-home-work__cancel,
.modal-send-hw__cancel {
    color: #473F95;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 12px;
    background-color: transparent;
    padding: 13px 46px;
}
.modal-send-hw__cancel {
    margin-right: 0;
    margin-left: 12px;
}
.add-home-work__save,
.modal-send-hw__save {
    color: #FFFFFF;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    background: #473F95;
    border-radius: 5px;
    padding: 13px 35px;
}
.uploaded-file {
    border: 1px dashed #473F95;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    width: 93px;
    position: relative;
    text-align: center;
}
.add-home-work__added-files-row .uploaded-file,
.add-home-work__added-files-row .new-file {
    margin-right: 36px;
}
.add-home-work__added-files-row .uploaded-file:last-child,
.add-home-work__added-files-row .new-file:last-child {
    margin-right: 0;
}
.uploaded-file__title {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 8px;
}
.uploaded-file__delete {
    position: absolute;
    right: -10px;
    top: -10px;
    border: none;
    border-radius: 50%;
    background-color: #D23168;
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-home-work__added-files {
    margin-top: 10px;
}
.add-home-work__added-files-row {
    display: flex;
    flex-direction: row;
}
.new-file {
    border: 1px dashed #473F95;
    box-sizing: border-box;
    border-radius: 4px;
    width: 93px;
    position: relative;
    text-align: center;
    padding: 12px 5px;
}
.new-file__add {
    border: none;
    background-color: transparent;
}
.new-file__img {
    width: 30px;
    height: 30px;
}
.add-home-work__added-files .add-home-work__added-files-row {
    margin-bottom: 8px;
}
.add-home-work__added-files .add-home-work__added-files-row:last-child {
    margin-bottom: 0;
}
/deep/ #modal-add-hw .modal-body {
    position: relative;
    background-image: url("/images/profile/wave-hw-modal.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}
.modal-send-hw__close {
    position: absolute;
    background: #D23168;
    border-radius: 100px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 44px;
    padding: 0;
    border: none;
    top: -22px;
    right: -22px;
}
.modal-send-hw__confirm {
    color: #D23168;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 40px;
    padding: 20px 0 18px 0;
}
.modal-send-hw__confirm-desc {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}
.modal-send-hw__content {
    padding: 14px 9px;
    text-align: center;
}
.modal-add-hw__close {
    background-color: transparent;
    border-radius: 0;
    min-width: auto;
    width: fit-content;
    padding: 0;
    border: none;
    margin-left: auto;
}
.modal-add-hw .modal-lg {
    max-width: 880px;
}
.modal-add-hw__title {
    color: #473F95;
    font-family: 'Open Sans', 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    padding: 8px 8px 21px;
}
.modal-add-hw__content .form-group {
    display: flex;
    flex-direction: column;
}
.modal-add-hw__content .form-group .form-label {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 4px;
}
/deep/ .modal-add-hw__content .form-group .el-input__inner {
    border: 2px solid #E3E2F2;
}
/deep/ .modal-add-hw__content .form-group .el-input__inner::placeholder {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}
.modal-add-hw__select {
    border-bottom: 2px solid #E3E2F2;
    padding-bottom: 13px;
}
.modal-add-hw__select-btns {
    padding-top: 19px;
    padding-bottom: 67px;
}
.modal-add-hw__apply {
    background: #EEEAFB;
    border-radius: 5px;
    margin-right: 10px;
    color: #473F95;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    padding: 14px 36px;
}
.modal-add-hw__reset {
    color: #fff;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #D23168;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
}
.add-hw-table {
    width: 100%;
    border-spacing: 5px;
    border-collapse: separate;
}
.add-hw-table-wrapper.loading {
    position: relative;
}
.add-hw-table-wrapper.loading::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('/images/preloader.gif');
    background-color: #ffffff9e;
    background-size: 25%;
    background-position: center;
    background-repeat: no-repeat;
}
.add-hw-table__header {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 8px;
    border-radius: 4px;
    background: #E3E2F2;
}
.add-hw-table__check-box {
    width: 48px;
    text-align: center;
}
.add-hw-table__theme {
    width: 309px;
}
.add-hw-table__subject {
    width: 196px;
}
.add-hw-table__task-type {
    width: 242px;
}
.add-hw-table__theme,
.add-hw-table__subject,
.add-hw-table__task-type {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 12px;
}
.add-hw-table__theme > div,
.add-hw-table__subject > div,
.add-hw-table__task-type > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}
/deep/ .el-checkbox__inner:hover {
    border-color: #473F95;
}
/deep/ .el-checkbox__inner {
    width: 19px;
    height: 19px;
}
/deep/ .el-checkbox__inner::after {
    height: 9px;
    left: 6px;
    top: 2px;
    width: 4px;
}
.pagination {
    border: none;
    box-shadow: none;
    margin-bottom: 30px;
    padding-top: 50px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
/deep/ .page-link:focus {
    box-shadow: none;
}
.modal-add-hw__footer {
    text-align: center;
    margin-bottom: 18px;
}
.modal-add-hw__cancel {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 12px;
    background-color: transparent;
    padding: 13px 46px;
}
.modal-add-hw__save {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    background: #473F95;
    border-radius: 5px;
    padding: 13px 35px;
}
.students-tab-mob {
    display: none;
}
.home-work-table__title {
    display: none;
}
.modal-add-hw__footer-btns {
    text-align: center;
    margin-top: 100px;
}
@media (max-width: 991px) {
    .students-tab {
        display: none;
    }
    .detailed-lesson-tabs {
        justify-content: flex-start;
        overflow-x: auto;
        white-space: nowrap;
    }
    .detailed-lesson-tabs .detailed-lesson-tabs__item:first-child {
        margin-right: 12px;
    }
    .detailed-lesson-tabs .detailed-lesson-tabs__item:first-child .detailed-lesson-tabs__title,
    .detailed-lesson-tabs .detailed-lesson-tabs__item:last-child .detailed-lesson-tabs__title {
        border-radius: 12px;
        padding: 11px 14px;
        width: fit-content;
        letter-spacing: -0.408px;
        display: block;
        height: 100%;
    }

    .students-tab-mob {
        display: block;
        background: #FFFFFF;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 62px 13px 200px 13px;
        position: relative;
    }
    .students-tab-mob .students-tab__back-btn > img {
        margin-right: 0!important;
    }
    .students-tab-mob .students-tab__back-btn {
        height: 55px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px 0;
    }
    .students-tab__class {
        font-size: 20px;
        line-height: 23px;
    }
    .students-tab__date {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 36px;
    }
    /deep/ .students-tab-mob .form-group {
        display: flex;
        flex-direction: column;
    }
    /deep/ .students-tab-mob .form-group .signin-label {
        color: #938FBA;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        font-variant: small-caps;
        padding: 12px 8px;
        background: #E3E2F2;
        border-radius: 4px;
    }
    .students-tab-mob__row {
        display: flex;
        flex-direction: row;
    }
    .students-tab-mob__row .form-group {
        width: 50%;
    }
    .students-tab-mob__row .form-group:first-child {
        margin-right: 8px;
    }
    .students-table__textarea {
        height: 62px;
        border: 1px solid #E3E2F2;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .students-tab-mob__fio {
        font-size: 14px;
        line-height: 16px;
        padding: 12px;
        background: #FFFFFF;
        border: 1px solid #E3E2F2;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 16px;
    }
    .students-tab-mob__item {
        margin-bottom: 38px;
    }
    .students-tab-mob__name-btn {
        color: #938FBA;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        font-variant: small-caps;
        padding: 12px 8px;
        background: #E3E2F2;
        border-radius: 4px;
        margin-bottom: 8px;
        border: none;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .students-tab-mob {
        background-image: url("/images/profile/wave-hw-modal.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    .students-tab-mob .students-tab__save-btn {
        display: flex;
        align-items: center;
        margin: 0 auto;
        background: #E897B3;
    }
    .add-home-work {
        flex-direction: column;
        padding-right: 0;
    }
    .add-home-work__col {
        width: 100%;
        min-width: initial;
        max-width: none;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
    }
    .home-work-tab {
        padding: 62px 16px 100px 16px;
    }
    .home-work-tab__add-btn {
        padding: 8px 19px;
        margin-left: 8px;
    }
    .home-work-tab__add-btn > img {
        margin-right: 0!important;
    }
    .home-work-tab__add-btn > div {
        display: none;
    }
    .home-work-tab__header {
        justify-content: flex-start;
    }
    .home-work-tab__title {
        font-size: 18px;
        line-height: 28px;
    }
    .home-work-table__title {
        display: block;
        color: #D23168;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
    }
    .home-work-table__header-row {
        display: none;
    }
    .home-work-table__body-row {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .home-work-table {
        width: 100%;
    }
    .home-work-table__delete {
        position: absolute!important;
        right: -10px;
        top: -10px;
    }
    .home-work-table__task-name,
    .home-work-table__task-type,
    .home-work-table__subject,
    .home-work-table__theme {
        width: 100%;
        padding: 10px 0;
    }
    .home-work-table tr:nth-child(even) {
        background: #FCFCFE;
        border: none;
    }
    .home-work-table__task-name > div, .home-work-table__task-type > div, .home-work-table__subject > div, .home-work-table__theme > div {
        -webkit-line-clamp: 3;
        font-size: 12px;
        line-height: 16px;
    }
    .home-work-tab__class {
        font-size: 20px;
        line-height: 23px;
        padding-bottom: 6px;
    }
    .home-work-tab__date {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    .home-work-tab__date > img {
        display: none;
    }
    .home-work-tab__header {
        padding-top: 0;
    }
    .add-home-work__textarea {
        margin-bottom: 16px;
    }
    .home-work-tab {
        background-image: url("/images/profile/wave-hw-modal.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        padding: 62px 13px 200px 13px;
    }
    #modal-add-hw .pagination {
        margin-bottom: 0;
    }
    /deep/ #modal-add-hw .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
    .add-home-work__upload {
        width: 100%;
    }
    .modal-add-hw__footer-btns {
        margin-top: 60px;
        margin-bottom: 80px;
    }
    .modal-add-hw__footer-btns .modal-add-hw__save,
    .modal-add-hw__footer-btns .modal-add-hw__cancel {
        padding: 13px 24px;
    }
    .modal-add-hw__select-btns {
        text-align: center;
    }
    .add-hw-table-wrapper {
        overflow-x: auto;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(1) {
        min-width: 48px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(2) {
        min-width: 309px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(3) {
        min-width: 196px;
    }    
    .add-hw-table > tr > .add-hw-table__header:nth-child(4) {
        min-width: 242px;
    }
    #modal-add-hw .pagination {
        padding-top: 28px;
    }

}
@media (max-width: 767px) {
    .students-tab-mob,
    .home-work-tab {
        padding: 62px 13px 100px 13px;
    }
    
    .pagination {
        margin-bottom: 72px;
        padding-top: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
    .add-home-work__upload {
        width: 100%;
        text-align: center;
    }
    /deep/ .el-upload-dragger {
        width: 281px;
    }
    .add-home-work__btns {
        display: flex;
    }
    .add-home-work__cancel,
    .add-home-work__save {
        width: 50%;
    }
    .detailed-lesson-tabs {
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .modal-send-hw__close {
        top: 12px;
        right: 12px;
    }
    .modal-send-hw__confirm {
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        padding-top: 20px;
        padding-bottom: 8px;
    }
    .modal-send-hw__save, .modal-send-hw__cancel {
        padding: 13px 20px;
        width: 129px;
    }
}

</style>
